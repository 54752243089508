import { render, staticRenderFns } from "./低功耗4G-BG2-B1.vue?vue&type=template&id=2990958d&scoped=true&"
import script from "./低功耗4G-BG2-B1.vue?vue&type=script&lang=js&"
export * from "./低功耗4G-BG2-B1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2990958d",
  null
  
)

export default component.exports